@import "../../assets/styles/variables";


.statCards {
  margin: 0 auto;
  margin-top: -100px;
  margin-bottom: 58px;

  max-width: max-content;
}

.slider {
  padding-left: 193px;
}

.slider :global .swiper-slide {
  width: 378px;
}

.slideImg {
  padding-top: 53px;
  padding-left: 32px;

  width: 378px;
  height: 526px;

  border-radius: 16px;
}

.title {
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  font-family: 'Etelka Pro', 'Arial', sans-serif;
  text-transform: uppercase;
}

@media(max-width: $mobile-width) {
  .statCards {
    margin-top: -15px;
    margin-bottom: 32px;
  }

  .slider {
    padding: 0;
  }

  .slider :global .swiper-wrapper {
    padding: 0 20px;
    width: calc(100vw - 40px);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
    gap: 4px;
  }

  .slider :global .swiper-slide {
    width: 165px;
    justify-self: center;
  }

  .slideImg {
    padding-top: 23px;
    padding-left: 8px;

    width: 165px;
    height: 230px;
  }

  .title {
    font-size: 17px;
  }
}
