@import "../../assets/styles/mixins";


.prefooter {
  position: relative;
  padding: 22px 40px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: end;

  font-size: 28px;
  font-weight: 300;
  color: #ffffff;
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;

  z-index: 3;
}

.email,
.social {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  gap: 40px;
} 

a {
  color: #ffffff;
}

@media(max-width: $tablet-width) {
  .prefooter {
    font-size: 21px;
  }
}

@media(max-width: $mobile-width) {
  .prefooter {
    padding-right: 20px;
    padding-left: 20px;

    justify-content: center;

    font-size: 19px;
  }

  .email,
  .social {
    font-size: 24px;
    grid-template-columns: auto;
    justify-content: center;
    gap: 22px;
  }
}

