@import '../../assets/styles/mixins.scss';

.section {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'left scale right';
  align-items: start;
  padding-left: 140px;
  padding-right: 140px;
  margin-top: 50px;
  margin-bottom: 35rem;
}
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: left;
}
.date {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 72px;
  font-weight: 700;
  line-height: 64.8px;
  color: #fff;
  text-transform: uppercase;
}
.cards {
  position: relative;
}

.card1 {
  position: absolute;
  top: 50px;
  left: -250px;
  transform: translate(0, 0) rotate(-10deg);
  will-change: transform, opacity;
}
.card2 {
  position: absolute;
  top: 70px;
  left: -30px;
  transform: translate(0, 0) rotate(4deg);

  z-index: 1;
}
.card3 {
  position: absolute;
  top: 0;
  left: 50px;
  transform: translate(0, 0) rotate(-10deg);
}
.card4 {
  position: absolute;
  top: 30px;
  left: 270px;
  transform: translate(0, 0) rotate(4deg);

  z-index: 1;
}
.wraptitle {
  text-align: center;
}
.topTitle {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  color: #fff;
  text-transform: uppercase;
}
.oneRun {
  border-radius: 16px;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  color: #ff3100;
  background-color: #fff;
  display: inline-block;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-left: 5px;
}
.card5 {
  position: absolute;
  top: 50px;
  left: -250px;
  transform: translate(0, 0) rotate(-10deg);
  will-change: transform, opacity;
}
.card6 {
  position: absolute;
  top: 140px;
  left: -10px;
  transform: translate(0, 0) rotate(4deg);

  z-index: 1;
}
.card7 {
  position: absolute;
  top: 70px;
  left: 50px;
  transform: translate(0, 0) rotate(-10deg);
}
.card8 {
  position: absolute;
  top: 10px;
  left: 270px;
  transform: translate(0, 0) rotate(4deg);

  z-index: 1;
}
.card9 {
  position: absolute;
  top: -200px;
  left: -350px;
  transform: translate(0, 0) rotate(-10deg);
  will-change: transform, opacity;
}
.card10 {
  position: absolute;
  top: -200px;
  left: 450px;
  transform: translate(0, 0) rotate(4deg);

  z-index: 1;
}
.card11 {
  position: absolute;
  top: 30px;
  left: 150px;
  transform: translate(0, 0) rotate(2deg);
}
.card12 {
  position: absolute;
  top: 30px;
  left: 150px;
  transform: translate(0, 0) rotate(4deg);
}
.card13 {
  position: absolute;
  left: 220px;
  top: 10px;
  transform: translate(0, 0) rotate(-5deg);
}
.card14 {
  position: absolute;
  left: 220px;
  top: 10px;
  transform: translate(0, 0) rotate(4deg);
}
.infoWrap {
  display: flex;
  gap: 4px;
}
.white {
  white-space: nowrap;
}
.zabeg {
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  display: inline-block;
  color: #ff3100;
  background-color: #fff;
  border-radius: 4px;
  padding: 0;
  padding-left: 3px;
  padding-right: 3px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.text {
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 35.2px;
  color: #fff;
  text-transform: none;
}
.infoBtn {
  padding: 0;

  background: none;
  border: none;

  cursor: pointer;
}
.card {
  width: 225px;
  height: 270px;
  background-color: #fff;
  border-radius: 10px;
  padding: 3px;
}

.cardImg {
  border-radius: 8px;
  width: 217.5px;
  height: 217.5px;
}
.right {
  grid-area: right;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  height: 100vh;
  width: 50px;
  grid-area: scale;
}
.stepContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.circleLineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #1caed7;
  border: 2px solid #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.innerCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ff3100;
}
.line {
  width: 2px;
  height: 280px;
  background-color: #fff;
}
.card {
  transform: translateX(0) rotate(var(--rotate, 0));
  transition: opacity 0.6s ease, transform 0.6s ease;
  opacity: 0;

  &.visible {
    transform: translateX(0) rotate(var(--rotate, 0));
    opacity: 1;
  }
}
.cardLeft {
  transform: translate(-60%, -65%) rotate(var(--rotate, 0));
}
.cardRight {
  transform: translate(60%, -65%) rotate(var(--rotate, 0));
}
.card1,
.card3,
.card5,
.card7,
.card9 {
  --rotate: -10deg;
}
.card2,
.card4,
.card6,
.card8,
.card10,
.card12,
.card14 {
  --rotate: 4deg;
}
.card11 {
  --rotate: 2deg;
}
.card13 {
  --rotate: -5deg;
}
@media (max-width: 1200px) {
  .cardLeft {
    transform: translate(-20%, -65%) rotate(var(--rotate, 0));
  }
  .cardRight {
    transform: translate(20%, -65%) rotate(var(--rotate, 0));
  }
}
@media (max-width: $tablet-width) {
  .section {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 'scale';
    align-items: start;
    padding-left: 80px;
    padding-right: 80px;
    margin-bottom: 3rem;
  }

  .wrap {
    display: none;
  }
  .foot {
    display: none;
  }
  .right {
    display: none;
  }
  .white {
    white-space: normal;
  }
  .line {
    height: 480px;
  }
  .card11 {
    top: 0;
  }
  .zabeg2020 {
    margin-top: 18rem;
  }
  .zabeg2023 {
    margin-top: 15rem;
  }
  .card12 {
    left: 220px;
    top: 0;
  }
  .zabeg2024 {
    margin-top: 20rem;
  }
  .zabeg2025 {
    margin-top: 21rem;
  }
}
@media (max-width: $mobile-width) {
  .cardLeft {
    transform: translate(-10%, -25%) rotate(var(--rotate, 0));
  }
  .cardRight {
    transform: translate(10%, -25%) rotate(var(--rotate, 0));
  }
  .section {
    grid-template-columns: 50px auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  .card11 {
    left: 130px;
    top: 10px;
  }
  .card12 {
    left: 160px;
  }
  .date {
    font-size: 40px;
  }
  .text {
    font-size: 28px;
  }
  .card {
    width: 142px;
    height: 170px;
    border-radius: 5px;
  }
  .cardImg {
    width: 137px;
    height: 137px;
  }
  .line {
    height: 380px;
  }
  .zabeg2020 {
    margin-top: 11.5rem;
  }
  .zabeg2023 {
    margin-top: 11.5rem;
  }
  .card13 {
    left: 150px;
    top: 20px;
  }
  .card14 {
    left: 150px;
    top: 20px;
  }
  .zabeg2024 {
    margin-top: 16rem;
  }
  .zabeg2025 {
    margin-top: 13.4rem;
  }
  .topTitle,
  .oneRun {
    font-size: 60px;
    line-height: 54px;
  }
}
@media (max-width: 506px) {
  .zabeg2023 {
    margin-top: 9rem;
  }
}
@media (max-width: 393px) {
  .section {
    grid-template-columns: 50px auto;
    padding-right: 5px;
    padding-left: 5px;
  }

  .card12 {
    left: 150px;
    top: -30px;
  }
  .zabeg2023 {
    margin-top: 6.5rem;
  }
  .zabeg2024 {
    margin-top: 14rem;
  }
  .zabeg2025 {
    margin-top: 9rem;
  }
}
