@import "../../assets/styles/mixins";


.startCity {
  margin-bottom: 72px;

  color: #ffffff;
  font-family: 'Etelka Pro', 'Arial', sans-serif;
}

.title {
  @include mainTitle;

  margin-bottom: 9px;

  color: #ffffff;
}

.description {
  margin-bottom: 64px;

  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
}

.content {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  grid-column-gap: 97px;
}

.img {
  width: 100%;
  height: 100%;

  object-fit: cover;

  border-radius: 16px;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;
  gap: 48px;
}

.item {
  display: grid;
  grid-template-columns: 64px 234px;
  grid-column-gap: 32px;
  align-items: center;
}

@media(max-width: 1280px) {
  .list {
    margin-bottom: 100px;
    gap: 40px;
  }
}

@media(max-width: 1120px) {
  .content {
    grid-column-gap: 36px;
  }

  .item {
    grid-column-gap: 32px;
  }
}

@media(max-width: $tablet-width) {

}

@media(max-width: $mobile-width) {
  .startCity {
    margin-bottom: 40px;
  }

  .title {
    margin-bottom: 8px;
  }

  .description {
    margin-bottom: 32px;

    font-size: 20px;
  }

  .content {
    grid-template-columns: 1fr;
  }

  .imgWrap {
    order: 1;
  }

  .list {
    margin-bottom: 30px;
  }
}
