@import "../../assets/styles/mixins";


.starterPack {
  margin-bottom: 40px;
  padding-top: 48px;
  padding-bottom: 51px;

  display: grid;
  //grid-template-columns: 1fr 480px;
  grid-template-columns: 1fr 384px;
  grid-column-gap: 56px;
  max-width: 1440px;
  overflow: hidden;

  color: #ffffff;
  font-family: 'Etelka Pro', 'Arial', sans-serif;
}

.title {
  margin-bottom: 71px;

  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
}

.mobile {
  display: none;
}

.label {
  margin-bottom: 18px;
}

.list {
  position: relative;
  margin: 0;
  margin-bottom: 91px;
  padding: 0;

  list-style: none;

  counter-reset: myCounter;
}

.item {
  position: relative;
  margin-bottom: 20px;
  padding-left: 37px;

  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;

  cursor: pointer;

  transition: color 0.25s;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  &:hover::before {
    border-color: rgba(255, 255, 255, 1);
  }

  &::before {
    @include pseudo;

    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    font-family: 'Etelka Pro', 'Arial', sans-serif;
    font-size: 12px;
    counter-increment: myCounter;
    content: counter(myCounter);
    transition: border-color 0.25s;
  }
}

.itemActive {
  margin-bottom: 38px;
  padding-left: 43px;

  font-size: 40px;
  color: rgba(255, 255, 255, 1);

  &::before {
    width: 32px;
    height: 32px;

    font-size: 18px;

    border-color: rgba(255, 255, 255, 1);
  }
}

.slider {
  margin: 0;
  padding-left: 0;
  padding-bottom: 57px;

  max-width: 417px;
}

.slider :global .swiper-pagination-bullet {
  background-color: #ffffff;
}

.slider :global .swiper-button-next {
  color: #ffffff;
}

.slider :global .swiper-button-prev {
  color: #ffffff;
}

.slideInner {
  margin: 0 auto;

  max-width: 345px;
}

.sliderImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sliderWrap {
  padding-top: 50px;
}

.placeholder {
  position: relative;

  max-width: 384px;
  min-height: 396px;

  background-color: #322fcd;
  border-radius: 16px;
}

.medalPlaceholder {
  width: 100%;
  height: 100%;
  object-fit: contain;
  mix-blend-mode: luminosity;
  opacity: 0.1;
}

.textPlaceholder {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  width: 100%;

  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
}

@media(max-width: 1030px) {
  .slider {
    max-width: 367px;
  }

  .slideInner {
    max-width: 295px;
  }
}

@media(max-width: $tablet-width) {
  .title {
    margin-bottom: 31px;
  }

  .list {
    margin-bottom: 30px;

    min-width: 265px;
  }

  .itemActive {
    font-size: 24px;
  }

  .sliderWrap {
    padding-top: 0;
  }

  .placeholder {

    max-width: 312px;
    min-height: 321px;
  }

  .textPlaceholder {
    font-size: 32px;
  }
}

@media(max-width: 850px) {
  .starterPack {
    margin-bottom: 0;

    grid-template-columns: 1fr;
    gap: 32px;
  }

  .title {
    margin-bottom: 0;

    font-size: 30px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .content {
    order: 1;
  }

  .itemActive {
    margin-bottom: 15px;
  }

  .slider {
    max-width: 335px;
  }

  .slideInner {
    max-width: 237px;
  }
}
