@import '../../assets/styles/mixins.scss';

.pageTop {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  color: #ffffff;

  background-color: #1d2144;
  background-image: url('../../assets/images/page-top-bg@2x.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.container {
  margin: 0 auto;
  padding: 2.25rem 3.75rem;
  padding-bottom: 3.75rem;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  flex: 1;
}

.head {
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  margin-bottom: 2rem;
}

.langWrap {
  position: relative;
  padding: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  gap: 10px;
  width: max-content;
  min-height: 50px;
  justify-self: right;

  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -0.5px;

  background-color: #ffffff;
  border-radius: 50px;
}

.inputLang:checked + .headerLabel {
  color: #ffffff;
  font-weight: bold;
  cursor: default;

  background-color: #1caed7;
}

.headerLabel {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  font-size: 14px;
  color: #232323;

  border-radius: 40px;

  cursor: pointer;

  &:not(:last-child)::before {
    @include pseudo;
    left: auto;
    right: -7px;
    transform: translateX(-50%);
    height: 100%;
    width: 2px;
    background-color: white;
  }
}

.cityWrapper {
  position: relative;
  margin-bottom: 87px;
  margin-left: 20px;

  width: max-content;

  font-size: 14px;
  font-weight: 300;
  color: #ffffff;

  &::before {
    @include pseudo;

    top: 50%;
    left: -24px;
    transform: translateY(-50%);

    width: 16px;
    height: 16px;

    background-image: url('../../assets/images/icons/icon-map-pin.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.city {
  font-weight: 800;
  color: #ffffff;
  text-decoration: none;

  background: none;
  border: 0;

  cursor: pointer;
}

.partnerLink {
  position: absolute;
  top: 0;
  right: 8.4%;
  padding: 25px 32px;
  padding-bottom: 40px;

  display: grid;
  justify-content: center;

  color: #000000;
  font-weight: 500;
  text-decoration: none;

  background-color: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.partnerLabel {
  margin-bottom: 25px;
}

.partnerLogo {
  margin: 0 auto;

  width: 139px;
  height: 42px;
}

.logos {
  margin-bottom: 10px;

  display: grid;
  grid-template-columns: auto auto;
  justify-content: left;
  align-items: center;
  gap: 28px;
}

.logoZabeg {
  width: 164px;
  height: 85px;
}

.content {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dateWrap {
  display: grid;
  grid-template-columns: max-content 345px;
  align-items: center;
  gap: 30px;
}

.date {
  color: #1caed7;
  font-size: 129px;
  text-transform: uppercase;
  font-family: 'Druk Cyr', 'Arial', sans-serif;

  font-weight: 700;
  background-color: #ffffff;
  border: 1px solid white;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  line-height: 120px;
  padding-bottom: 10px;
}

.description {
  font-size: 32px;
  font-weight: 500;
}

.sloganWrapper {
  width: 100%;
  margin: 0 auto;
  aspect-ratio: 4 / 1;
  max-height: 25vh;
  min-height: 100px;
}

.slogan {
  text-transform: uppercase;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-weight: 700;
  line-height: 1.1;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.focus {
  color: #ff3100;
}

.anchor {
  position: relative;
  padding: 16px 69px;

  display: block;
  width: 100%;

  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  background-color: #ff3100;
  border-radius: 10px;

  transition: all 0.2s;

  &:hover {
    background-color: #ff3100;
  }
}

@media (max-width: 1050px) {
  .head {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }

  .timerWrap {
    grid-column: 1 / -1;
    justify-self: center;
    order: 1;
  }
}

@media (max-width: $tablet-width) {
  .sloganWrapper {
    aspect-ratio: 3 / 1;
    max-height: 20vh;
  }

  .slogan {
    font-size: 16.5vw;
  }

  .anchor {
    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: $mobile-width) {
  .pageTop {
    padding-top: 12px;
  }

  .container {
    padding: 0 1.25rem 1.25rem;
  }

  .cityWrapper {
    font-size: 12px;

    z-index: 4;

    &::before {
      width: 24px;
      height: 24px;

      background-size: contain;
    }
  }

  .city {
    padding: 0;

    font-size: 14px;
  }

  .partnerLink {
    display: none;
  }

  .head {
    margin-bottom: 0;

    row-gap: 20px;
  }

  .logoZabeg {
    width: 80px;
    height: 42px;
  }

  .dateWrap {
    margin: 1rem auto;

    grid-template-columns: max-content minmax(100px, 190px);
    gap: 12px;

    max-width: max-content;
  }

  .date {
    font-size: 50px;
    max-width: 45vw;

    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0;
    line-height: 1;
    padding-bottom: 5px;
    white-space: nowrap;
  }

  .description {
    font-size: 16px;
    max-width: 45vw;
  }
  .langWrap {
    gap: 0;
  }
  .logos {
    gap: 10px;
  }

  .logo {
    width: 70px;
    height: auto;
  }

  .content {
    gap: 10px;
  }

  .sloganWrapper {
    aspect-ratio: 2.5 / 1;
    max-height: 15vh;
  }
}
