@font-face {
  font-family: 'Druk Cyr';
  src: local('Druk Cyr Bold'), local('DrukCyr-Bold'),
    url('DrukCyr-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Druk Cyr';
  src: local('Druk Cyr'), local('DrukCyr-Medium'),
    url('DrukCyr-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'DIN Pro Cond';
  src: local('DIN Pro Condensed Regular'), local('DINPro-CondensedRegular'),
    url('DINPro-CondensedRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: local('DIN Pro Condensed Bold'), local('DINPro-CondensedBold'),
    url('DINPro-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: local('DIN Pro Condensed Medium'), local('DINPro-CondensedMedium'),
    url('DINPro-CondensedMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
