// Цвета
$color-main: #ffffff;
$color-brand: #306de9;
$color-secondary: #d6356b;

$color-focus: #fb4f64;

// Шрифт
$font-family-main: "Panton", sans-serif;

// Брейкпоинты
$desktop-width: 1440px;
$tablet-width: 991px;
$mobile-width: 768px;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
