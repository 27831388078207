@import "../../assets/styles/mixins";


.bg1 {
  position: relative;
  background: linear-gradient(180deg, #5A5AFF 0%, #2121D1 100%);
  background-size: 100% 77%;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg2 {
  background: linear-gradient(180deg, #5A5AFF 0%, #2121D1 100%), linear-gradient(180deg, #2D2DD3 0%, #1A1A8A 100%);
  background-size: 100% 95%;
  background-repeat: no-repeat;
  background-position: center top;
}

@media(max-width: $mobile-width) {
  .bg1 {
    background-size: 100% 90%;
  }

  .bg2 {
    background-size: 100% 92%;
  }
}
