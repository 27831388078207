@import "../../assets/styles/mixins";


.footer {
  position: relative;
  padding: 22px 40px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: end;

  font-size: 28px;
  font-weight: 300;
  color: #ffffff;
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;

  z-index: 3;
}

.copyright,
.incarnation {
  display: grid;
  grid-template-columns: auto auto;
  align-items: end;
  gap: 40px;
}

.logoMl {
  justify-self: right;
}

@media(max-width: $tablet-width) {
  .footer {
    font-size: 21px;
  }

  .logoLiga {
    width: 80px;
    height: 51px;
  }

  .logoMl {
    width: 72px;
    height: 20px;
  }
}

@media(max-width: $mobile-width) {
  .footer {
    padding-right: 20px;
    padding-left: 20px;

    grid-template-columns: auto auto;
    justify-content: space-between;

    font-size: 19px;
  }

  .logoLiga {
    width: 95px;
    height: 60px;
  }

  .logoMl {
    width: 102px;
    height: 28px;
  }

  .copyright,
  .incarnation {
    grid-template-columns: auto;
    gap: 22px;
  }
}

