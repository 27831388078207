@import "../../assets/styles/mixins";

.routeEvent {
  margin-bottom: 93px;

  color: #000000;
  font-family: 'Etelka Pro', 'Arial', sans-serif;
}

.title {
  margin-bottom: 67px;

  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
}

.top {
  margin-bottom: 34px;

  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 40px;
}

.topItem {
  display: grid;
  grid-template-columns: 1fr;

  font-size: 24px;
  font-weight: 500;
}

.label {
  margin-bottom: 13px;

  font-size: 16px;
  font-weight: 300;
}

.cityBtn {
  padding: 0;

  display: grid;
  grid-template-columns: auto 1fr;
  align-content: center;
  gap: 8px;

  font-size: 24px;
  font-weight: 500;
  line-height: 1.1;
  font-family: 'Etelka Pro', 'Arial', sans-serif;

  background: transparent;
  border: 0;

  //cursor: pointer;

  //&:hover,
  //&:active, {
  //  color: #322fcd;
  //}

  //&:hover svg path,
  //&:active svg path {
  //  fill: #322fcd;
  //}
}

.bottom {
  display: grid;
  grid-template-columns: 1fr 281px;
  gap: 8px;
}

.tabPanelInner {
  position: relative;
  margin-bottom: 24px;

  min-height: 421px;

  border-radius: 16px;
}

.tabs :global(.react-tabs__tab-list) {
  display: flex;

  font-weight: 500;

  border: 0;
}

.tab {
  padding: 0 16px;

  font-weight: 300;

  border-right: 3px solid #000000;

  &:last-child {
    border: 0;
  }

  cursor: pointer;
}

//.tabs :global(.react-tabs__tab) {
//  padding: 0 16px;
//
//  font-weight: 300;
//
//  border-right: 3px solid #000000;
//
//  &:last-child {
//    border: 0;
//  }
//}

.tabs :global(.react-tabs__tab--selected) {
  color: #cc2128;
  font-weight: 500;

  //border: 0;
  border-radius: 0;
  border-color: #cc2128;
  //border-right: 3px solid #cc2128;
}

.placeholderRoute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-content: center;


  color: #ffffff;
  font-weight: 300;
  text-align: center;

  background-image: url("../../assets/images/route-placeholder@1x.webp");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
}

.placeholderRouteTitle {
  margin-top: 50%;
  transform: translateY(-50%);

  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
}

.followSocials {
  margin-top: 100px;
  margin-bottom: 10px;

  font-weight: 300;
}

.socialsWrapper {
  margin: 0 auto;

  width: 72px;
}

.medical {
  margin-bottom: 24px;
  padding-top: 36px;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 19px;

  display: flex;
  flex-direction: column;
  min-height: 421px;

  color: #ffffff;
  font-weight: 300;
  text-decoration: none;

  background-color: #cc2128;
  border-radius: 16px;
}

.descriptionMedical {
  margin-top: auto;
  margin-bottom: 16px;
}

.medicalBtn {
  @include btnPrimary;

  color: #cc2128;
  font-size: 18px;
  font-weight: 400;

  background-color: #ffffff;

  &:hover,
  &:active {
    color: #ffffff;
  }
}

.medicalTitle {
  font-size: 18px;
  font-weight: 500;
}

.medicalImg {
  animation: shake 1s cubic-bezier(.3,.06,.2,.9) infinite;
}

.medicalSocials {
  display: grid;
  grid-template-columns: 210px auto;

  font-weight: 300;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, -1px, 0) rotate(5deg);
  }

  20%, 80% {
    transform: translate3d(2px, -4px, 0) rotate(-2deg);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, -1px, 0) rotate(3deg);
  }

  40%, 60% {
    transform: translate3d(4px, 2px, 0) rotate(-4deg);
  }
}

@media(max-width: $tablet-width) {
  .top {
    gap: 16px;
  }

  .topItem {
    font-size: 20px;
  }
}

@media(max-width: $mobile-width) {
  .routeEvent {
    margin-bottom: 32px;
  }

  .title {
    margin-bottom: 24px;

    max-width: 250px;

    font-size: 30px;
  }

  .top {
    display: block;
  }

  .topItem {
    margin-bottom: 24px;
  }

  .label {
    margin-bottom: 8px;
  }

  .cityBtn {
    grid-template-columns: auto auto;
    justify-content: start;
  }

  .bottom {
    grid-template-columns: 1fr;
  }

  .tabs {
    order: 1;
  }

  .tabListLabel {
    margin-bottom: 13px;

    display: block;
  }

  .tabs :global(.react-tabs__tab) {
    &:nth-child(2) {
      padding-left: 0;
    }
  }

  .medical {
    padding: 24px 16px;

    display: block;
    min-height: 173px;

    font-size: 14px;
  }

  .imgDesc {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
  }

  .medicalImg {
    width: 106px;
  }

  .medicalTitle {
    margin-bottom: 24px;
  }

  .descriptionMedical {
    min-height: 80px;
  }

  .medicalSocials {
    display: none;
  }
}
