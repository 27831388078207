@import "../../assets/styles/mixins";


.gallery {
  margin: 0 auto;
  margin-bottom: 89px;

  max-width: max-content;
}

.slider {
  margin-top: -26px;
  padding-left: 0;
}

.slide {
  width: 282px;
}

.imgWrap {
  width: 282px;
  height: 274px;
}

.img {
  width: 100%;
  height: 100%;

  border-radius: 8px;

  object-fit: contain;
}

@media(max-width: $mobile-width) {
  .gallery {
    margin-bottom: 24px;
  }

  .slide {
    width: 184px;
  }

  .imgWrap {
    width: 184px;
    height: 178px;
  }
}
