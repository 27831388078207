@import '../../assets/styles/mixins.scss';

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.globus {
  max-width: 1157px;
  border-radius: 25px;
  background-color: #fff;
  text-transform: uppercase;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 40px;
}

.title {
  margin-top: 60px;
  text-align: center;
  font-size: 62px;
  font-weight: 700;
  line-height: 82.8px;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  color: #232323;
}
.red {
  border-radius: 16px;

  color: #fff;
  background-color: #ff3100;
  display: inline-block;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}
.cityBlock {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 25px;
  row-gap: 20px;
}
.item {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 30.8px;
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  text-transform: none;
  color: #232323;
  white-space: nowrap;
}

.item::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ff3100;
  border-radius: 50%;
  margin-right: 4px;
  transform: translateY(2px);
}
.globusBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.img {
  max-width: 1009px;
  max-height: 432px;
  width: 100%;
  height: 100%;
}

@media (max-width: 1250px) {
  .cityBlock {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 1100px) {
  .cityBlock {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: $mobile-width) {
  .cityBlock {
    grid-template-columns: repeat(2, 1fr);
  }
  .img {
    max-width: 353px;
    max-height: 150px;
  }
  .title {
    font-size: 58px;
    font-weight: 700;
    line-height: 54px;
  }
  .globus {
    padding-right: 20px;
    padding-left: 20px;
  }
}
