@import "./assets/fonts/fonts.scss";

body {
  margin: 0;

  background-color: #1caed7;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
  outline: none;
}

*::before, *::after {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6, p, ul, figure {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
