@import "../../assets/styles/mixins";


.container {
  position: fixed;
  bottom: 50px;
  right: 0;

  display: grid;
  grid-template-columns: 24px auto;
  height: 148px;

  color: white;
  font-family: 'Etelka Pro', 'Arial', sans-serif;

  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  z-index: 3;
  overflow: hidden;
}

.toggle {
  background-color: #671b1f;
  background-image: url("../../assets/images/icons/icon-right-without-outline.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: 0;

  cursor: pointer;
}

.content {
  width: 270px;

  overflow: hidden;

  transition: width 0.7s;
}

.inner {
  padding: 16px;

  width: 270px;
  min-height: 148px;

  background-color: #cc2128;
}

.title {
  margin-bottom: 10px;
  padding-left: 32px;

  font-size: 20px;
  font-weight: 400;
  line-height: 22px;

  background-image: url("../../assets/images/icons/icon-question-mark-circle-outline.svg");
  background-repeat: no-repeat;

}

.desc {
  margin-bottom: 24px;

  max-width: 204px;

  font-size: 12px;
}

.anchor {
  color: #ffffff;
  font-size: 12px;

  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.closed .content {
  width: 0;
}

.closed .toggle {
  background-image: url("../../assets/images/icons/icon-left-without-outline.svg");
}

@media(max-width: $mobile-width) {
  .container {
    top: 65vh;
  }
}
