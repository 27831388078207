@import '../../assets/styles/mixins';

.faq {
  padding-top: 70px;
  padding-bottom: 112px;

  font-family: 'Etelka Pro', 'Arial', sans-serif;
}

.title {
  text-align: center;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
  margin-bottom: 50px;
}
.red {
  border-radius: 16px;

  color: #ff3100;
  background-color: #fff;
  display: inline-block;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-left: 7px;
}

.bg {
  //background-color: #1caed7;
}

.categories {
  margin-bottom: 40px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(341px, 1fr));
  gap: 20px;
}

.categoryButton {
  padding: 30px 35px;

  width: 100%;

  color: #ffffff;
  font-size: 36px;
  text-align: left;
  text-transform: uppercase;
  font-family: 'Druk Cyr', 'Arial', sans-serif;

  background-color: #007fa3;
  border: 0;
  border-radius: 15px;

  background-image: url('../../assets/images/icons/icon-right.svg');
  background-repeat: no-repeat;
  background-position: center right 35px;

  cursor: pointer;
}

.categoryButtonActive {
  background-image: url('../../assets/images/icons/icon-down.svg');
}

.categoryPanelMobile {
  display: none;
}

.categoryPanelDesktop {
  margin-top: 50px;
}

.accordion {
  margin-bottom: 48px;
}

.accordionItem {
  margin-bottom: 20px;
  padding: 30px 35px;

  background-color: #ffffff;
  border-radius: 15px;
}

.accordionHead {
  display: grid;
  grid-template-columns: 1fr auto;

  cursor: pointer;
}

.accordionLabel {
  color: #007fa3;
  font-size: 30px;
  font-weight: 700;
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
}

.accordionButton {
  position: relative;
  padding-right: 40px;

  background: none;
  border: 0;

  cursor: inherit;

  &::before {
    @include pseudo;

    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);

    width: 32px;
    height: 32px;

    background-image: url('../../assets/images/icons/icon-right2.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.accordionPanel {
  margin-top: 18px;
  margin-right: 24px;

  font-size: 28px;
  line-height: 140%;
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;

  & p:not(:last-child) {
    margin-bottom: 16px;
  }

  & ul {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 15px;

    list-style-type: disc;
    list-style-position: inside;
  }
}

.accordionItemOpen {
  & .accordionButton {
    &::before {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}

.link {
  @include btnPrimary;

  padding: 17px;

  font-size: 18px;

  max-width: 320px;
}

@media (max-width: $mobile-width) {
  .faq {
    padding-top: 48px;
    padding-bottom: 66px;
  }

  .title {
    margin-bottom: 30px;
    font-size: 60px;
    font-weight: 700;
    line-height: 54px;
  }

  .categories {
    margin-bottom: 51px;

    grid-template-columns: 1fr;
    gap: 24px;
  }

  .categoryButton {
    font-size: 30px;
  }

  .categoryPanelMobile {
    margin-top: 20px;

    display: block;
  }

  .categoryPanelDesktop {
    display: none;
  }

  .accordion {
    margin-bottom: 24px;
  }

  .accordionLabel {
    font-size: 24px;
  }

  .accordionButton {
    width: max-content;
    min-height: 24px;

    &::before {
      top: 8px;
      transform: translateY(0);
      width: 18px;
      height: 24px;
    }
  }

  .accordionItemOpen {
    & .accordionButton {
      &::before {
        transform: translateY(0) rotate(90deg);
      }
    }
  }

  .accordionPanel {
    margin-right: 0;

    font-size: 20px;
  }
}

//@media(max-width: 500px) {
//  .accordionHead {
//    grid-template-columns: 1fr;
//  }
//}
