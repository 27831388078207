@import '../../assets/styles/mixins.scss';

.statLines {
  padding-top: 60px;
  padding-bottom: 100px;

  display: grid;
  gap: 8px;

  overflow: hidden;
}

@keyframes scrollLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes scrollRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

.lineWrap {
  display: flex;
  align-items: center;
}

.lineWrapLeft {
  animation: scrollLeft 90s linear infinite;
}

.lineWrapRight {
  animation: scrollRight 90s linear infinite;
}

.line {
  display: flex;
  align-items: center;
  align-content: center;

  color: #232323;
  font-size: 224px;
  font-weight: 500;
  line-height: 80%;
  text-transform: uppercase;
  font-family: 'Druk Cyr', 'Arial', sans-serif;

  p {
    padding: 0 24px;

    min-width: max-content;
  }
}

.valueColor1 {
  color: #ff3100;
  background-color: #fff;
  display: inline-block;
  border-radius: 16px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 20px;
}

.valueColor2 {
  color: #fff;
  background-color: #ff3100;
  display: inline-block;
  border-radius: 16px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 20px;
}
.valueColor3 {
  color: #fff;
  display: inline-block;
  padding-top: 0;
  padding-bottom: 20px;
}

.img {
  display: block;

  border-radius: 24px;
}

@media (max-width: $tablet-width) {
  .statLines {
    padding-bottom: 60px;

    gap: 10px;
  }

  .line {
    font-size: 107px;

    p {
      padding: 0 16px;
    }
  }

  .valueColor1,
  .valueColor2,
  .valueColor3 {
    padding-bottom: 9px;
  }

  .img {
    width: 108px;
    height: 94px;
    border-radius: 10px;
  }
}
