@import 'variables';

@mixin centerman {
  margin: 0 auto;
  padding: 2.25rem 3.75rem;
  padding-bottom: 0;
  max-width: $desktop-width;
}

@mixin btnPrimary {
  padding: 0.8rem;

  display: block;
  width: 100%;
  max-width: 20.6rem;

  font-family: 'Etelka Pro', 'Arial', sans-serif;
  font-size: 1.25rem;
  color: $color-main;
  text-align: center;
  text-decoration: none;

  background-color: #cc2128;
  border: none;
  border-radius: 2px;

  transition: background-color 0.25s;

  cursor: pointer;

  &:hover,
  &:active {
    background-color: #322fcd;
  }
}

@mixin pseudo {
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
}

@mixin centering {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin mainTitle {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 72px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: $mobile-width) {
    font-size: 60px;
  }
}

@mixin mainTitleMobile {
  font-size: 2.25rem;
}

@mixin decorLine {
  @include pseudo;

  left: 0;
  bottom: -1rem;
  width: 6rem;
  height: 0.5rem;
  border-radius: 0.2rem;
  background-color: $color-brand;
}

@mixin mainText {
  font-family: 'Panton SemiBold', 'Arial', sans-serif;
  font-size: 1.25rem;
  color: $color-brand;
}

@mixin listCounter {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 3rem;
    counter-increment: myCounter;
    content: '0' counter(myCounter) '.';
  }
}

@mixin scrollbar {
  // For FireFox
  scrollbar-width: thin;
  scrollbar-color: hsla(0, 0%, 100%, 0.75) rgba(0, 0, 0, 0.4);

  //For Chrome, Edge, Safari
  &::-webkit-scrollbar {
    width: 0.2rem;
    //background-color: red;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 100%, 0.75);
    border-radius: 0.6rem;
  }
}
@mixin title {
  font-size: 60px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
}
@mixin container {
  margin: 0 auto;
  padding: 0 232px;

  max-width: $desktop-width;

  @media (max-width: $desktop-width) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: $tablet-width) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@mixin card {
  padding: 20px;

  background-color: #ffffff;
  border-radius: 20px;
}
@mixin btnPrimary {
  padding: 16px;

  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;

  color: #009dc9;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Druk Cyr', 'Arial', sans-serif;

  background-color: $color-main;
  border-radius: 10px;
  border: none;

  transition: background-color 0.25s;

  cursor: pointer;

  &:hover,
  &:active {
    background-color: #e6e6e6;
  }
}
