@import "../../assets/styles/variables";

.description {
  padding-top: 179px;
  padding-bottom: 152px;

  font-family: 'Etelka Pro', 'Arial', sans-serif;
}

.text {
  margin-bottom: 64px;

  color: #000000;
  font-size: 64px;
}

.eventName {
  color: #322fcd;
}

.focus {
  color: #cc2128;
}

.slogan {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.5);
}

@media(max-width: $tablet-width) {
  .description {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  .text {
    margin-bottom: 32px;

    font-size: 46px;
  }

  .slogan {
    font-size: 26px;
  }
}

@media(max-width: $mobile-width) {
  .description {
    padding-top: 48px;
    padding-bottom: 38px;
  }

  .text {
    margin-bottom: 32px;

    font-size: 30px;
  }

  .slogan {
    font-size: 20px;
  }
}
