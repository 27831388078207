@import '../../assets/styles/mixins.scss';


.timer {
  padding: 15px;
  padding-top: 10px;

  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;

  background-color: #ffffff;
  border-radius: 10px;
}

.timerLabel {
  margin-right: 17px;
  font-weight: 300;
}

.numWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60px;
}

.number {
  display: flex;
  align-items: center;
  color: #1caed7;
  font-size: 48px;
  font-weight: 700;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
}

.labelNum {
  color: #232323;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
}

.divider {
  margin-bottom: 16px;

  display: block;

  color: #1caed7;
  font-size: 48px;
  font-weight: 700;
  font-family: 'Times New Roman', sans-serif;
}

@media(max-width: $mobile-width) {
  .timer {
    flex-wrap: wrap;
  }

  .timerLabel {
    width: 100%;
  }

  .numWrap {
    font-size: 20px;
  }
}
