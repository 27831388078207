.list {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  gap: 36px;
  list-style: none;
}

.horizontalList {
  grid-template-columns: repeat(auto-fill, minmax(24px, 1fr));
  gap: 16px;
}
