@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
  z-index: 3;
}

.container {
  position: relative;
  //padding: 3.1rem 3.7rem;
  //padding-bottom: 2.1rem;
  //width: 100%;
  //max-width: 82.5rem;
  //background-color: #2d5ce2;
  //border-radius: 1.8rem;
}

.closeBtn {
  position: absolute;
  background-color: transparent;
  background-image: url("../../../assets/images/icons/icon-close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  cursor: pointer;
  z-index: 10;
}

//@media (max-width: $tablet-width) {
//  .closeBtn {
//    width: 2.5rem;
//    height: 2.5rem;
//    background-size: 55%;
//  }
//}
