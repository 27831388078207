@import "../../assets/styles/variables";


.howWaIt {
  margin-bottom: 88px;

  color: #ffffff;
  font-family: 'Etelka Pro', 'Arial', sans-serif;
}

.title {
  margin-bottom: 8px;

  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
}

.desc {
  margin-bottom: 24px;

  font-size: 24px;

  opacity: 0.5;
}

.videoPlayer {
  position: relative;
  margin: 0 auto;
  border-radius: 16px;
}

.buttonWrapper {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 47%;
  cursor: pointer
}

.wrapper {
  position: relative;
}

.video {
  width: 100%;
  border-radius: 16px;
}

.link {
  color: #ffffff;
  text-decoration: none;
}

.btnPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;

  width: 80px;
  height: 80px;

  background-color: #ffffff;
  background-image: url("../../assets/images/icons/icon-play.svg");
  background-repeat: no-repeat;
  background-position: 52% 52%;
  border: 0;
  border-radius: 50%;

  cursor: pointer;

  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: #d6d6d6;
  }
}

@media(max-width: $mobile-width) {
  .howWaIt {
    margin-bottom: 55px;
  }

  .title {
    font-size: 30px;
  }

  .link {
    display: none;
  }
}
