@import '../../assets/styles/mixins.scss';

.container {
  height: 419px;
  width: 100%;
  background-image: url('../../assets/images/format.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 40px;
  padding-right: 40px;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  display: inline-block;
  padding: 10px;
  padding-top: 0;
  border-radius: 16px;
  background-color: #fff;
  color: #ff3100;
  max-width: 443px;
  text-transform: uppercase;
  white-space: nowrap;
}
.format,
.format1,
.format2,
.format3 {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 72px;
  font-weight: 500;
  line-height: 86.4px;
  color: #fff;

  display: inline-block;
  border-radius: 20px;

  text-transform: uppercase;
  white-space: nowrap;
  padding: 6px 65px 6px 65px;
}

.format {
  background-color: #48ac33;
}
.format1 {
  background-color: #ec6632;
}
.format2 {
  background-color: #a54292;
  padding: 6px 55px 6px 55px;
}
.format3 {
  background-color: #0f7fa4;
  padding: 6px 45px 6px 45px;
}
.formats {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

@media (max-width: $tablet-width) {
  .formats {
    flex-wrap: wrap;
  }
}
@media (max-width: $mobile-width) {
  .formats {
    flex-direction: column;
    align-items: center;
  }
  .container {
    height: 558px;
    width: 100%;
    background-image: url('../../assets/images/format_mobile.webp');
    padding-left: 10px;
    padding-right: 10px;
  }
  .format,
  .format1,
  .format2,
  .format3 {
    font-size: 32px;
    line-height: 38.4px;
    padding: 6px 85px 6px 85px;
    border-radius: 10px;
  }
  .title {
    font-size: 60px;
    line-height: 54px;
  }
}
