@import '../../../../assets/styles/mixins';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;

  width: 100%;
  height: 100%;
  background-color: rgba(28, 174, 215, 0.6);
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 1000;
}

.modalContent {
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;

  width: 35px;
  height: 35px;

  background: none;
  border: none;

  cursor: pointer;

  z-index: 10000;
}

.closeBtnIcon {
  transform: rotate(45deg);

  width: 20px;
  height: 20px;

  rect {
    fill: $color-brand;
  }
}

@media (max-width: $mobile-width) {
  .closeBtn {
    top: 15px;
    right: 15px;
  }
}

@media (max-width: 425px) {
  .modalOverlay {
    padding: 20px 0;
  }
}
