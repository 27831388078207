@import '../../../assets/styles/variables.scss';

.container {
  margin: 0 auto;
  padding: 2.25rem 3.75rem;
  padding-bottom: 0;
  max-width: $desktop-width;
}

@media (max-width: $mobile-width) {
  .container {
    padding: 0 1.25rem;
  }
}
