@import "../../assets/styles/mixins";

.formats {
  margin-bottom: 137px;
}

.title {
  @include mainTitle;

  margin-bottom: 55px;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 8px;
}

.item {
  font-size: 0;
  color: #ffffff;
  font-family: 'Etelka Pro', 'Arial', sans-serif;

  transition: opacity 0.45s;
}

.itemHover {
  opacity: 0.3;
}

.itemContent {
  padding: 16px 24px;
  padding-bottom: 30px;

  display: grid;
  align-content: space-between;

  min-height: 480px;

  background-color: #322fcd;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 281px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  transition: background 0.8s;
}

.item:first-child .itemContent {
  background-color: #00d970;
  background-image: url("../../assets/images/level12.svg");
}

.item:nth-child(2) .itemContent {
  background-color: #1d9bce;
  background-image: url("../../assets/images/level22.svg");
}

.item:nth-child(3) .itemContent {
  background-color: #e93641;
  background-image: url("../../assets/images/level32.svg");
}

.item:nth-child(4) .itemContent {
  background-color: #ffac47;
  background-image: url("../../assets/images/level42.svg");
}

.itemHoverActive .itemContent {
  background-size: 630px;
}

.itemHoverActive:first-child .itemContent {
  background-position: right -80px top -130px;
}

.itemHoverActive:nth-child(2) .itemContent {
  background-position: right -80px top -85px;
}

.itemHoverActive:nth-child(3) .itemContent {
  background-position: right -120px top -85px;
}

.itemHoverActive:nth-child(4) .itemContent {
  background-position: right -80px top -85px;
}

.distance {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}

.level {
  font-size: 32px;
  font-weight: 300;
  text-transform: uppercase;
}

.description {
  font-size: 20px;
}

.pricesWrap {
  font-size: 16px;
  font-weight: 300;
}

.currentPriceWrap {
  margin-bottom: 8px;
  padding-bottom: 8px;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: end;

  border-bottom: 1px solid #ffffff;
}

.price {
  justify-self: right;
  font-size: 40px;
  font-weight: 700;
}

.nextPriceWrap {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  font-size: 12px;
}

.nextPriceDate {
  font-weight: 500;
}

.nextPrice {
  font-weight: 700;
}

.btn {
  padding: 19px;

  width: 100%;

  font-size: 16px;
  color: #ffffff;
  font-family: 'Etelka Pro', 'Arial', sans-serif;

  background-color: #2222d2;
  border: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  cursor: pointer;

  transition: background-color 0.25s;

  &:hover,
  &:active {
    background-color: #cc2128;
  }
}

@media(max-width: $mobile-width) {
  .formats {
    margin-bottom: 32px;
  }

  .title {
    margin-bottom: 16px;
  }

  .itemContent {
    padding: 16px;
    padding-bottom: 24px;

    min-height: 200px;
  }

  .item:first-child .itemContent,
  .item:nth-child(2) .itemContent,
  .item:nth-child(3) .itemContent,
  .item:nth-child(4) .itemContent {
    background-image: none;
  }

  .itemTop {
    margin-bottom: 8px;

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  .level {
    font-size: 19px;
  }

  .description {
    margin-bottom: 8px;

    font-size: 14px;
    font-weight: 300;
  }

  .currentPriceWrap {
    margin-bottom: 13px;
    padding-bottom: 8px;

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: end;

    font-size: 14px;

    border-bottom: 1px solid #ffffff;
  }

  .price {
    font-size: 20px;
  }

  .nextPrice {
    font-size: 14px;
  }
}
