@import "../../assets/styles/variables";


.entertainment {
  margin-bottom: 90px;

  color: #ffffff;
  font-family: 'Etelka Pro', 'Arial', sans-serif;
}

.title {
  margin-bottom: 52px;

  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  column-gap: 38px;
  row-gap: 40px;
}

.item {
  text-align: center;
}

.iconWrap {
  margin-bottom: 32px;

  min-height: 121px;
}

.icon {
  margin: 0 auto;
}

.itemTitle {
  font-size: 20px;
  font-weight: 400;
}

@media(max-width: $mobile-width) {
  .entertainment {
    margin-bottom: 56px;
  }

  .title {
    margin-bottom: 48px;
    padding-right: 40px;

    font-size: 30px;
  }

  .list {
    grid-template-columns: repeat(auto-fit, minmax(156px, 1fr));
    column-gap: 21px;
    row-gap: 32px;
  }

  .iconWrap {
    margin-bottom: 0;

    min-height: 124px;
  }

  .icon {
    transform: scale(0.766);
  }

  .itemTitle {
    font-size: 16px;
  }
}
