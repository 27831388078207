@import "../../assets/styles/variables";


.statLines {
  overflow: hidden;
}

.wrapper {
  width: max-content;
}

.line {
  font-family: 'Etelka Pro', 'Arial', sans-serif;
  font-size: 96px;
  font-weight: 700;
  line-height: 0.95;
  color: #322fcd;
  text-transform: uppercase;
  white-space: nowrap;
}

.line2,
.line4 {
  margin-left: -40%;
}

.cities1 {
  color: #cc2128;
}

.textShadow {
  color: #ffffff;
  text-shadow: 1px 0 #322fcd, -1px 0 #322fcd, 0 1px #322fcd, 0 -1px #322fcd,
  1px 1px #322fcd, -1px -1px #322fcd, 1px -1px #322fcd, -1px 1px #322fcd;
}

@media(max-width: $tablet-width) {
  .line {
    font-size: 62px;
  }
}

@media(max-width: $mobile-width) {
  .line {
    font-size: 46px;
  }
}
