@import '../../../assets/styles/mixins';

.modalInner {
  display: grid;
  gap: 4px;
  max-width: 644px;

  color: #232323;
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  font-family: 'Open Sans', 'Arial', sans-serif;

  background-color: $color-main;
  border-radius: 20px;
}

.content {
  margin: 0 auto;
  padding: 40px;

  max-width: 500px;

  text-align: center;
}

.title {
  @include title;

  margin-bottom: 20px;
}

.text {
  margin-bottom: 20px;
}

.img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: contain;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.socials {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: 10px;
}

// .socialsIcon {
//   path {
//     fill: #ff3100;
//   }
// }

.socialsItem {
  &:hover {
    .socialsIcon {
      path {
        fill: #d1f5ff;

      }
    }
  }
}

@media (max-width: 425px) {
  .modalInner {
    max-width: 280px;

    font-size: 10px;
  }

  .content {
    padding: 13px;
    padding-bottom: 22px;

    max-width: 206px;
  }

  .title {
    font-size: 24px;
  }
}
