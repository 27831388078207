@import '../../assets/styles/mixins.scss';

.wrapper {
  position: relative;
  margin-bottom: 104px;
}

.videoFrame {
  width: 100%;
  border-radius: 25px;

  object-fit: cover;

  position: relative;
  top: 5px;
}

@media (max-width: $mobile-width) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
  .videoFrame {
    width: 343px;
    height: 287px;
  }
}
