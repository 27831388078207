@import '../../assets/styles/mixins';

.plan {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  width: 182px;
  height: 230px;
  top: 123px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.text {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 26.4px;
  color: #232323;
  margin-top: auto;
  text-transform: uppercase;
}
.title {
  text-align: center;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.red {
  border-radius: 16px;

  color: #ff3100;
  background-color: #fff;
  display: inline-block;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 800px;
  gap: 15px;
  margin-top: 30px;
}

@media (max-width: $mobile-width) {
  .title {
    margin-bottom: 15px;
    font-size: 60px;
    font-weight: 700;
    line-height: 54px;
  }
  .items {
    grid-template-columns: 1fr 1fr;
  }
  .item {
    width: 167px;
    height: 230px;
    border-radius: 10px;
  }
  .plan {
    margin-top: 20px;
  }
}
