@import '../../assets/styles/mixins';

.inner {
  @include container;

  padding-top: 40px;
  padding-bottom: 40px;

  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  margin-top: 50px;
}
.dis {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
  color: #007fa3;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  max-width: 257px;
}

.title {
  text-align: center;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  color: #fff;
  margin-bottom: 10px;
}
.red {
  border-radius: 16px;

  color: #ff3100;
  background-color: #fff;
  display: inline-block;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}
.wrapDesc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.desc {
  margin-bottom: 40px;

  color: $color-main;
  text-align: center;

  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 35.2px;
  max-width: 663px;
  text-transform: none;
}

.itemsPreviewWrap {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 21px;
  max-width: 896px;
}

.itemsCard {
  @include card;
  display: flex;
  flex-direction: column;
  max-width: 437px;
  min-height: 420px;
  width: 100%;
}

.items {
  margin-bottom: auto;

  display: grid;
  gap: 11px;
}

.item {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  color: #bcbcbc;
  font-size: 36px;
  line-height: 100%;
  font-weight: 500;

  cursor: pointer;

  transition: color 0.25s;

  &:hover {
    color: #ff3100;
  }
}

.itemActive {
  color: #ff3100;
}

.footnote {
  max-width: 243px;

  color: #bcbcbc;
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  text-transform: lowercase;
  font-family: 'Open Sans', 'Arial', sans-serif;
}

.previewCard {
  @include card;

  position: relative;

  max-width: 438px;
  max-height: 420px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemImg {
  margin: 0 auto;

  display: block;

  filter: drop-shadow(0 25px 50px rgba(212, 212, 212, 0.72));
}

.buttonSizes {
  @include btnPrimary;

  font-size: 8px;
  color: $color-main;
  background-color: $color-brand;

  transition: background-color 0.25s;

  &:hover {
    background-color: #048bb1;
  }
}

.titleHowToGet {
  margin-bottom: 40px;
}

.howToGet {
  margin-bottom: 20px;

  display: grid;
  grid-template-columns: minmax(300px, 1fr) 1fr;
  gap: 21px;
}

.documentsCard,
.linksCard {
  @include card;
}

.documentsLabel {
  margin-bottom: 17px;

  max-width: 365px;

  font-size: 36px;
  line-height: 100%;
}

.documents {
  padding-left: 20px;

  display: grid;
  gap: 10px;
  max-width: 330px;

  list-style: disc;
}

.document,
.linksText {
  font-size: 13px;
  font-weight: 400;
  line-height: 130%;
  text-transform: none;
  font-family: 'Open Sans', 'Arial', sans-serif;
}

.linksCard {
  padding: 10px;

  display: grid;
  align-content: space-between;
  gap: 10px;
}

.linksText {
  margin-bottom: 21px;
  padding: 15px 10px 0;
}

.link {
  @include btnPrimary;

  transition: background-color 0.25s;
}

.linkDocs {
  color: #232323;
  opacity: 0;
  background-color: #d9d9d9;

  &:hover {
    background-color: #c1c1c1;
  }
}

.linkPermissions {
  color: $color-main;

  background-color: #ff3100;

  &:hover {
    background-color: #e03006;
  }
}

.linkMed {
  color: $color-main;

  background-color: $color-brand;

  &:hover {
    background-color: #048bb1;
  }
}

.getInfo {
  margin-bottom: 20px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.getInfoItem {
  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 13px;
  color: $color-main;
  text-align: center;
  text-transform: none;
  font-family: 'Open Sans', 'Arial', sans-serif;

  background-color: #009dc9;
  border-radius: 20px;
}

.getInfoFootnote {
  font-size: 12px;
  font-weight: 600;
  color: $color-main;
  text-align: center;
  text-transform: none;
  font-family: 'Open Sans', 'Arial', sans-serif;
}

.modalInner {
  padding-top: 60px;
  padding-right: 90px;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: end;
  max-width: 976px;

  background-color: $color-main;
  border-radius: 25px;
}

.tables {
  margin-bottom: 10px;
}

.tableLabel {
  margin-bottom: 10px;

  font-weight: 900;
  color: $color-brand;
  text-align: center;
  text-transform: none;
  font-family: 'Open Sans', 'Arial', sans-serif;
}

.table {
  margin-bottom: 20px;

  width: 100%;

  text-align: center;
  text-transform: none;
  font-family: 'Open Sans', 'Arial', sans-serif;

  table-layout: fixed;
  border-collapse: collapse;
}

.tr {
  &:nth-child(even) {
    background-color: #f1f1f1;
  }

  &:nth-child(odd) {
    .td {
      padding: 10px 0;
    }
  }
}

.th {
  padding-bottom: 12px;

  font-weight: 700;
  text-align: center;
  vertical-align: top;

  &:first-child {
    width: 8%;
  }
}

.thValue {
  display: block;

  font-weight: 900;
  color: $color-brand;
}

.td {
  font-weight: 700;

  &:first-child {
    width: 8%;

    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  &:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

@media (max-width: 1100px) {
  .startPack {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .itemsPreviewWrap {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

@media (max-width: $tablet-width) {
  .itemsPreviewWrap {
    grid-template-columns: auto;
    max-width: 438px;
  }
}

@media (max-width: $mobile-width) {
  .title {
    margin-bottom: 15px;
    font-size: 60px;
    font-weight: 700;
    line-height: 54px;
  }
  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    overflow: hidden;
    gap: 9px;
  }
  .desc {
    margin-bottom: 20px;
    font-size: 28px;
  }

  .itemsPreviewWrap {
    margin-bottom: 25px;

    gap: 15px;
  }

  .itemsCard,
  .previewCard {
    border-radius: 25px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    min-height: auto;
  }
  .previewCard {
    min-height: 382px;
  }
  .itemsCard {
    flex-direction: row;
    max-height: 80px;
    overflow: hidden;
  }
  .item {
    font-size: 20px;
  }
  .itemImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .footnote {
    color: #fff;
    text-align: center;
  }
  .foot {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .titleHowToGet {
    margin-bottom: 30px;
  }

  .howToGet {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .documentsCard,
  .linksCard {
    border-radius: 25px;
  }

  .linksCard {
    padding: 15px;

    gap: 8px;
  }

  .documents {
    max-width: none;
  }

  .document,
  .linksText {
    font-size: 14px;
  }

  .linksText {
    padding: 15px 15px 0;
  }

  .getInfo {
    margin-bottom: 8px;

    grid-template-columns: 1fr;
    gap: 5px;
  }

  .getInfoItem {
    padding: 12px;

    background-color: transparent;
    border: 1px solid $color-main;
    border-radius: 15px;
  }

  .getInfoFootnote {
    text-align: left;
    font-weight: 400;
  }
}
