@import 'src/assets/styles/mixins.scss';

.certificates {
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
}

.certificates {
  border-radius: 10px;
}

@media (max-width: $mobile-width) {
  .certificates {
    grid-template-columns: auto;
    gap: 24px;
  }
}
