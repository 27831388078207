@import '../../assets/styles/mixins.scss';

.container {
  padding-top: 100px;
  padding-bottom: 100px;
  /*   padding-left: 140px;
  padding-right: 100px; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 92px;
  font-weight: bold;
  line-height: 82.8px;
  display: inline-block;
  padding: 10px;
  padding-top: 0;
  border-radius: 16px;
  background-color: #ff3100;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
}
.transitionText {
  text-align: center;
  max-width: 898px;
  display: inline-block;
  text-transform: uppercase;
}
.text {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 72px;
  font-weight: 500;
  line-height: 72px;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  color: #232323;
}
.transitionTextZh {
  & .text {
    font-size: 52px;
    line-height: 52px;
  }
}
.desc {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  justify-self: center;

  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 35.2px;

  color: #fff;
  line-height: 100%;
  text-align: center;
}
.img {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  width: 100%;
  height: 100%;

  object-fit: contain;

  border-radius: 25px;
}
.imgWrap {
  margin: 0 auto;
  margin-top: 3rem;

  display: grid;
  align-items: center;
  max-width: 636px;
}
.titleText {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 92px;
  font-weight: 500;
  line-height: 82.8px;
  color: #232323;
  margin-left: 10px;
}
.description {
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 35.2px;
  letter-spacing: -0.01em;
  text-align: center;
  max-width: 580px;
}
.card {
  background-color: #f6f6f6;
  width: 242px;
  height: 233px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardBig {
  width: 374px;
  height: 233px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1caed7;
}
.items {
  display: flex;
  flex-direction: column;
}
.count {
  font-size: 72px;
  font-weight: bold;
  line-height: 57.6px;
  color: #ff3100;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
}
.item {
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 35.2px;
}
.cards {
}
.block {
  display: flex;
  gap: 20px;
}
.foot {
  display: flex;
  margin-top: 40px;
}
.imgFoot {
  max-width: 504px;
  max-height: 487px;
  width: 100%;
  border-radius: 25px;
  margin-left: 20px;
}
.titleWrap {
  margin-bottom: 2rem;
}
@media (max-width: 1190px) {
  .foot {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
@media (max-width: $tablet-width) {
  .imgFoot {
    margin-left: 0;
  }
}

@media (max-width: $mobile-width) {
  .title {
    font-size: 60px;
    line-height: 54px;
  }
  .titleText {
    font-size: 60px;
    line-height: 54px;
  }
  .description {
    font-size: 28px;
    line-height: 30.8px;
  }
  .container {
    padding-bottom: 60px;
    /* 
    padding-left: 5px;
    padding-right: 5px; */
  }
  .transitionText {
  }
  .text {
    font-size: 60px;
    line-height: 60px;
  }
  .imgWrap {
    max-width: 352px;
    height: 476px;
  }
  .img {
    object-fit: fill;
  }
  .desc {
    font-size: 32px;
    font-weight: 500;
    line-height: 35.2px;
    max-width: 325px;
  }
  .block {
    flex-direction: column;
  }
  .card {
    max-width: 374px;
    min-width: 353px;
    width: 100%;
  }
  .cardBig {
    max-width: 374px;
    min-width: 353px;
    width: 100%;
  }
  .imgFoot {
    max-width: 353px;
    max-height: 343px;
  }
}
