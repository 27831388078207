.bg {
  background-image: url('../../assets/images/artboard2.webp'), url('../../assets/images/artboard3.webp');
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: contain;
}
.wraptitle {
  text-align: center;
  text-transform: uppercase;
  padding-top: 4rem;
}
.topTitle {
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  color: #fff;
}
.oneRun {
  border-radius: 16px;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  color: #ff3100;
  background-color: #fff;
  display: inline-block;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-left: 5px;
}
.text {
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 35.2px;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: none;
}

@media (max-width: 768px) {
  .topTitle,
  .oneRun {
    font-size: 60px;
    line-height: 54px;
  }

  .text {
    font-size: 28px;
    font-weight: 500;
    line-height: 30.8px;
    max-width: 230px;
  }
  .wraptitle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
