@import '../../assets/styles/mixins';

.formats {
  padding-bottom: 80px;
}

.title {
  text-align: center;
  font-size: 92px;
  font-weight: 700;
  line-height: 82.8px;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
  color: #fff;
  margin-bottom: 40px;
  text-transform: uppercase;
  margin-bottom: 50px;
}
.red {
  border-radius: 16px;

  color: #ff3100;
  background-color: #fff;
  display: inline-block;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-left: 7px;
}
.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-around;
  gap: 20px;

  border-radius: 20px;
}

.item {
  padding: 10px;

  font-size: 0;
  color: #232323;
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;

  transition: opacity 0.45s;

  background-color: #ffffff;
  border-radius: 20px;
}

.itemHover {
}

.itemContent {
  display: grid;
  align-content: space-between;
}

.imgWrap {
  height: 175px;

  background-color: #cfcfcf;
  background-image: url('../../assets/images/placeholder-img.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;

  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: transform 0.8s;
}

.itemHoverActive .img {
  transform: scale(1.2);
}

.inner {
  padding: 16px 10px;

  display: grid;
  grid-template-columns: 1fr auto;
}

.innerSoon {
  & .valueCountry {
    color: rgba(255, 49, 0, 0.3);
  }

  & .valueCity {
    color: rgba(35, 35, 35, 0.3);
  }
}

.countryWrap,
.cityWrap,
.dateWrap {
  display: grid;
}

.countryWrap {
  margin-bottom: 8px;

  grid-column: 1 / -1;
}

.label {
  font-size: 20px;
  color: rgba(35, 35, 35, 0.3);
}

.value {
  font-size: 28px;
}

.valueCountry {
  font-size: 48px;

  color: #ff3100;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Druk Cyr', 'Arial', sans-serif;
}

.dateWrap {
  grid-column: 2 / 3;
}

.btn {
  padding: 21px;

  display: block;
  width: 100%;

  color: #ffffff;
  font-size: 24px;
  font-family: 'DIN Pro Cond', 'Arial', sans-serif;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;

  background-color: #1caed7;
  border: 0;
  border-radius: 10px;

  cursor: pointer;

  transition: background-color 0.25s;

  &:hover,
  &:active {
    background-color: #ff3100;
  }
}

.linkDisabled {
  color: #232323;

  background-color: #f6f6f6;

  pointer-events: none;
}

@media (max-width: $tablet-width) {
  .list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: $mobile-width) {
  .formats {
    padding-bottom: 32px;
  }

  .title {
    margin-bottom: 16px;
    font-size: 60px;
    font-weight: 700;
    line-height: 54px;
  }

  .valueCountry {
    font-size: 48px;
  }
}

@media (max-width: 600px) {
  .list {
    grid-template-columns: 1fr;
  }

  .inner {
    padding: 8px 12px;
  }

  .btn {
    padding: 12px;
  }
}
